import { request } from './request';

export type OrderStatusType =
  | 'awaiting_payment'
  | 'expired'
  | 'error'
  | 'external_address_specified'
  | 'fiat_paid'
  | 'payout'
  | 'waiting_for_kyc'
  | 'completed';

export interface IOrder {
  customer_id: string;
  order_id: string;
  customer_email: string;
  customer_external_id: string;
  partner_order_id: string | null;
  cryptocurrency: string;
  fiat_currency: string;
  cryptocurrency_amount: string;
  fiat_currency_amount: string;
  payout_address: string;
  created_at: string;
  expires_at?: string;
  payform_url: string;
  status: OrderStatusType;
  error_details: string;
  customer_changed_payout_address: boolean;
  partner_payout_usd_amount?: string;
}

export interface ICreateOrderBody {
  cryptocurrency: string;
  fiat_currency: string;
  fiat_currency_amount: string;
  customer_email: string;
  customer_external_id?: string;
  partner_order_id?: string;
  customer_name?: string;
  customer_country_alpha3?: string;
  customer_usa_state?: string;
  customer_city?: string;
  customer_address?: string;
  customer_zip_code?: string;
  customer_phone?: string;
}

export interface IOrderParams {
  cryptocurrency_amount: string;
  usd_amount: string;
  fiat_currency_amount_min: string;
  fiat_currency_amount_max: string;
}

export interface IGetOrderParams {
  cryptocurrency: string;
  fiat_currency: string;
  fiat_currency_amount: string;
}

export type StatusType = Extract<
  OrderStatusType,
  'payout' | 'completed' | 'awaiting_payment' | 'waiting_for_kyc'
>;

export const allowedStatuses: Array<StatusType> = [
  'payout',
  'completed',
  'awaiting_payment',
  'waiting_for_kyc',
];

export interface IGetOrders {
  limit?: number;
  skip?: number;
  filter?: FilterEnum;
  from_date?: string;
  to_date?: string;
}

export enum FilterEnum {
  All = 'all',
  HideExpired = 'hideExpired',
  Completed = 'completed',
}

export async function getOrders({
  limit,
  skip,
  filter,
  from_date,
  to_date,
}: IGetOrders) {
  let statuses;
  if (filter === FilterEnum.HideExpired) {
    statuses = allowedStatuses;
  }
  if (filter === FilterEnum.Completed) {
    statuses = ['completed'];
  }

  return request<Array<IOrder>>('get', 'v1/orders', {
    limit,
    skip,
    from_date,
    to_date,
    statuses,
  });
}

export async function getOrderByOrderId(orderId: string) {
  return request<IOrder>('get', `v1/order/${orderId}`);
}

export async function getOrderByPartnerOrderId(partnerOrderId: string) {
  return request<IOrder>('get', `v1/order_by_partner_id/${partnerOrderId}`);
}

export async function getCustomerOrdersByCustomerId(
  customerId: string,
  limit?: number,
  skip?: number,
) {
  return request<Array<IOrder>>('get', `v1/customer/${customerId}/orders`, {
    limit,
    skip,
  });
}

export async function createOrder(data: ICreateOrderBody) {
  return request<IOrder>('post', `v1/order`, data);
}

export async function getOrderParams(data: IGetOrderParams) {
  return request<IOrderParams>('get', `v1/order_params`, data);
}

export async function downloadCSV({
  from_date,
  to_date,
  filter,
}: {
  from_date: string;
  to_date: string;
  filter: FilterEnum;
}) {
  let statuses;
  if (filter === FilterEnum.HideExpired) {
    statuses = allowedStatuses;
  }
  if (filter === FilterEnum.Completed) {
    statuses = ['completed'];
  }

  return request(
    'get',
    'v1/orders/csv',
    {
      from_date,
      to_date,
      statuses,
    },
    {
      responseType: 'blob',
    },
  );
}
