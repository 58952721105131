import React, { memo, PropsWithChildren } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames';

interface IProps {
  loading: boolean;
  className?: string;
}

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute' as const,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1201,
    backgroundColor: 'rgba(255, 255, 255, .9)',
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    color: 'rgba(0, 0, 0, 0.3)',
  },
});

export const Loader = memo(
  ({ loading, children, className }: PropsWithChildren<IProps>) => {
    const classes = useStyles();
    if (loading) {
      return (
        <div className={classes.wrapper}>
          <div className={cx(classes.progress, className)}>
            <CircularProgress />
          </div>
          {children}
        </div>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  },
);
