import md5 from 'crypto-js/md5';

import { request } from './request';

interface ISignIn {
  email: string;
  token: string;
}

export async function signIn(email: string, password: string) {
  const result = await request<ISignIn>('post', 'auth', {
    email,
    password: md5(password).toString(),
  });
  if (result.data) {
    localStorage.setItem('token', result.data.token);
    localStorage.setItem('email', result.data.email);
  }
  return result;
}
