import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'wouter';
import {
  Button,
  colors,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  endOfDay,
  formatDistanceToNow,
  isValid,
  parseISO,
  startOfDay,
  subMonths,
} from 'date-fns';
import { useDebounce, useInterval, useSearchParam } from 'react-use';
import isEqual from 'lodash/isEqual';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
  downloadCSV as downloadCSVApi,
  FilterEnum,
  getOrderByOrderId,
  getOrderByPartnerOrderId,
  getOrders,
  IOrder,
  OrderStatusType,
} from '../../api/orders.api';
import { downloadFile, isOrderId, toLocaleString } from '../../helpers';
import { IColumn, InfiniteTable } from '../InfiniteTable';
import { IResponse } from '../../api/request';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

const LIMIT = 20;

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  csvWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    maxHeight: 'calc(100vh - 200px)',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  search: {
    width: 400,
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  dates: {
    marginLeft: theme.spacing(1),
  },
  date: {
    marginLeft: theme.spacing(1),
    width: 180,
  },
  formGroup: {
    flexDirection: 'row',
  },
}));

export function getOrdersColumns<T extends { link: string }>(
  classes: T,
): Array<IColumn<IOrder>> {
  return [
    {
      field: 'status',
      label: 'Status',
    },
    {
      label: 'Customer',
      stopPropagation: true,
      format: (row) => (
        <Link className={classes.link} href={`/customer/${row.customer_id}`}>
          {row.customer_email || row.customer_external_id}
        </Link>
      ),
    },
    {
      field: 'created_at',
      label: 'Created At',
      minWidth: 180,
      format: (row) => {
        const createdAt = new Date(row.created_at);
        return (
          <>
            {createdAt.toLocaleString()}
            <br />
            {formatDistanceToNow(createdAt, { addSuffix: true })}
          </>
        );
      },
    },
    {
      label: 'Fiat currency',
      align: 'right',
      minWidth: 150,
      format: (row) => (
        <>
          {toLocaleString(row.fiat_currency_amount)} {row.fiat_currency}
        </>
      ),
    },
    {
      field: 'partner_payout_usd_amount',
      label: 'Partner payout amount',
      minWidth: 200,
      align: 'right',
      format: (row) =>
        row.partner_payout_usd_amount ? (
          <>{row.partner_payout_usd_amount} USD</>
        ) : (
          '—'
        ),
    },
    {
      label: 'Cryptocurrency',
      align: 'right',
      format: (row) => (
        <>
          {Number(row.cryptocurrency_amount)} {row.cryptocurrency}
        </>
      ),
    },
    {
      field: 'payout_address',
      label: 'Payout address',
    },
    {
      field: 'payform_url',
      label: 'Payform url',
      minWidth: 120,
      format: (row) => (
        <a href={row.payform_url} target="_blank" rel="noopener noreferrer">
          link
        </a>
      ),
      stopPropagation: true,
    },
    {
      field: 'error_details',
      label: 'Error details',
      minWidth: 140,
    },
    {
      label: 'Partner Order Id',
      field: 'partner_order_id',
      minWidth: 140,
    },
    {
      label: 'Order Id',
      field: 'order_id',
    },
    {
      field: 'expires_at',
      label: 'Expires At',
      minWidth: 140,
      format: (row) =>
        row.expires_at ? new Date(row.expires_at).toLocaleString() : '—',
    },
    {
      field: 'customer_changed_payout_address',
      label: 'Customer changed payout address',
      minWidth: 280,
      format: (row) => row.customer_changed_payout_address.toString(),
    },
  ];
}

const rowColors: {
  [K in OrderStatusType]?: string;
} = {
  awaiting_payment: colors.yellow.A400,
  completed: colors.green.A400,
  expired: colors.grey.A200,
  error: colors.red.A200,
};

export function getRowColor(status: OrderStatusType) {
  return rowColors[status] || colors.lightBlue.A200;
}

const now = new Date();
const dateFromDefault = subMonths(now, 1).toISOString().split('T')[0];
const dateToDefault = now.toISOString().split('T')[0];

export const Orders = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState<Array<IOrder>>([]);
  const [location, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  const areDatesValid = useMemo(
    () => isValid(parseISO(dateFrom)) && isValid(parseISO(dateTo)),
    [dateFrom, dateTo],
  );

  useEffect(() => {
    if (areDatesValid) {
      setSkip(0);
      setOrders([]);
      setHasMore(true);
    }
  }, [dateFrom, dateTo, areDatesValid]);

  const filterParam = useSearchParam('filter');

  const [ordersFilter, setOrdersFilter] = useState<FilterEnum>(
    filterParam ? (filterParam as FilterEnum) : FilterEnum.All,
  );

  const onSkip = useCallback(() => {
    setSkip((s) => s + LIMIT);
  }, []);

  const columns = useMemo(() => getOrdersColumns(classes), [classes]);

  const fetchOrders = useCallback(async () => {
    setErrorMessage(undefined);
    setLoading(true);
    const { data, error } = await getOrders({
      limit: LIMIT,
      skip,
      filter: ordersFilter,
      from_date: startOfDay(parseISO(dateFrom)).toISOString(),
      to_date: endOfDay(parseISO(dateTo)).toISOString(),
    });
    if (data) {
      if (data.length > 0) {
        setOrders((s) => [...s, ...data]);
      } else {
        setHasMore(false);
      }
    }
    if (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  }, [skip, ordersFilter, dateFrom, dateTo]);

  const fetchActiveOrders = useCallback(async () => {
    const { data } = await getOrders({
      limit: orders.length,
      filter: ordersFilter,
      from_date: startOfDay(parseISO(dateFrom)).toISOString(),
      to_date: endOfDay(parseISO(dateTo)).toISOString(),
    });
    if (data) {
      setOrders((s) => {
        const newOrders: Array<IOrder> = [];
        const state = [...s];
        let isChanged = false;
        data.forEach((order) => {
          const index = state.findIndex((x) => x.order_id === order.order_id);
          if (index === -1) {
            newOrders.push(order);
          } else if (!isEqual(state[index], order)) {
            isChanged = true;
            state[index] = order;
          }
        });
        if (newOrders.length > 0 || isChanged) {
          return [...newOrders, ...state];
        }
        return s;
      });
    }
  }, [ordersFilter, dateFrom, dateTo, orders.length]);

  useEffect(() => {
    if (!search) {
      setHasMore(true);
      setOrders([]);
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      void fetchOrders();
    }
  }, [fetchOrders, search]);

  const fetchOrder = useCallback(async (value: string) => {
    setErrorMessage(undefined);
    setLoading(true);
    let response: IResponse<IOrder> | undefined;
    if (isOrderId(value)) {
      response = await getOrderByOrderId(value);
    } else {
      response = await getOrderByPartnerOrderId(value);
    }

    const { data, error } = response;

    if (data) {
      setOrders([data]);
    } else {
      setOrders([]);
    }
    if (error) {
      setErrorMessage(error.message);
    }
    setHasMore(false);
    setLoading(false);
  }, []);

  useDebounce(
    () => {
      if (search) {
        setSkip(0);
        void fetchOrder(search);
      }
    },
    300,
    [search, fetchOrder],
  );

  const onFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasMore(true);
      setOrders([]);
      setSkip(0);
      setOrdersFilter(event.target.value as FilterEnum);
    },
    [],
  );

  useDebounce(
    () => {
      if (ordersFilter) {
        setLocation(`${location}?filter=${ordersFilter}`);
      } else {
        setLocation(location);
      }
    },
    200,
    [ordersFilter, location, setLocation],
  );

  useInterval(fetchActiveOrders, 10 * 1000);

  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);

  const downloadCSV = useCallback(async () => {
    if (areDatesValid) {
      const { response, error } = await downloadCSVApi({
        from_date: startOfDay(parseISO(dateFrom)).toISOString(),
        to_date: endOfDay(parseISO(dateTo)).toISOString(),
        filter: ordersFilter,
      });
      if (response) {
        const [, fileName] = response.headers['content-disposition'].split(
          'filename=',
        );
        downloadFile(response.data, fileName);
      }
      if (error) {
        setSnackbarValue(error.message, SnackbarType.Error);
      }
    }
  }, [areDatesValid, dateFrom, dateTo, ordersFilter, setSnackbarValue]);

  const onTableRowClick = useCallback(
    (_, row: IOrder) => {
      setLocation(`/order/${row.order_id}`);
    },
    [setLocation],
  );

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Link href="/order/create">
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add order
          </Button>
        </Link>
        <TextField
          className={classes.search}
          label="Search"
          placeholder="Order Id or Partner Order Id"
          variant="outlined"
          size="small"
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
      </div>
      <Paper className={classes.filter}>
        <RadioGroup
          className={classes.formGroup}
          value={ordersFilter}
          onChange={onFilterChange}
        >
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel
            value="hideExpired"
            control={<Radio />}
            label="Hide expired"
          />
          <FormControlLabel
            value="completed"
            control={<Radio />}
            label="Completed"
          />
        </RadioGroup>
        <div className={classes.csvWrapper}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
          <div className={classes.dates}>
            <TextField
              label="From"
              variant="outlined"
              size="small"
              type="date"
              value={dateFrom}
              onChange={(evt) => {
                setDateFrom(evt.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.date}
            />
            <TextField
              label="To"
              variant="outlined"
              size="small"
              type="date"
              value={dateTo}
              onChange={(evt) => {
                setDateTo(evt.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.date}
            />
          </div>
        </div>
      </Paper>
      <InfiniteTable
        columns={columns}
        data={orders}
        rowKey="order_id"
        onSkip={onSkip}
        hasMore={hasMore}
        loading={loading}
        onTableRowClick={onTableRowClick}
        rowStyle={(row) => ({
          borderLeft: `10px solid ${getRowColor(row.status)}`,
        })}
        errorMessage={errorMessage}
      />
    </div>
  );
};
