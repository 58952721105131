import React, { memo, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { RouteComponentProps } from 'wouter';
import { formatDistanceToNow } from 'date-fns';

import { Loader } from '../Loader';
import { getWithdrawalById, IWithdrawal } from '../../api/finance.api';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  orderId: {
    marginLeft: theme.spacing(2),
  },
}));

export const Withdrawal = memo(({ params }: RouteComponentProps) => {
  const classes = useStyles();

  const [withdrawal, setWithdrawal] = useState<IWithdrawal>();
  const [loading, setLoading] = useState(false);

  const fetchWithdrawal = useCallback(async () => {
    setLoading(true);
    const { data } = await getWithdrawalById(params.withdrawalId);
    if (data) {
      setWithdrawal(data);
    }
    setLoading(false);
  }, [params.withdrawalId]);

  useEffect(() => {
    void fetchWithdrawal();
  }, [fetchWithdrawal]);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography gutterBottom variant="h4">
          Withdrawal
        </Typography>
        <Typography className={classes.orderId} variant="subtitle1">
          {withdrawal?.withdrawal_id}
        </Typography>
      </div>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <Loader loading={loading}>
            {withdrawal && (
              <>
                <p>
                  <strong>Created At: </strong>
                  {new Date(withdrawal.created_at).toLocaleString()}
                  <br />
                  {formatDistanceToNow(new Date(withdrawal.created_at), {
                    addSuffix: true,
                  })}
                </p>
                <p>
                  <strong>Status: </strong>
                  {withdrawal.status}
                </p>
                <p>
                  <strong>Amount: </strong>
                  {withdrawal.amount}
                </p>
                <p>
                  <strong>Withdrawal Id: </strong>
                  {withdrawal.withdrawal_id}
                </p>
                <p>
                  <strong>Completed At: </strong>
                  {withdrawal.completed_at
                    ? new Date(withdrawal.completed_at).toLocaleString()
                    : '—'}
                </p>
                <p>
                  <strong>Updated At: </strong>
                  {withdrawal.updated_at
                    ? new Date(withdrawal.updated_at).toLocaleString()
                    : '—'}
                </p>
              </>
            )}
          </Loader>
        </CardContent>
      </Card>
    </div>
  );
});
