function getVariable(name: string) {
  if (process.env.NODE_ENV === 'development') {
    return process.env[name];
  }
  // eslint-disable-next-line no-underscore-dangle
  return (window as any)._env_[name] as string;
}

export const config = {
  apiUrl: getVariable('REACT_APP_API_URL'),
};
