import React, { useCallback, useRef } from 'react';
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 'calc(100vh - 182px)',
  },
  table: {
    borderCollapse: 'collapse',
  },
  rowOnClick: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  progress: {
    position: 'absolute',
    width: 'calc(100% - 248px)',
    marginLeft: -1,
    marginTop: -1,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
}));

export interface IColumn<T> {
  field?: keyof T;
  label: string;
  minWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  format?: (row: T) => React.ReactElement | string;
  stopPropagation?: boolean;
}

interface IProps<T> {
  data: Array<T>;
  columns: Array<IColumn<T>>;
  onTableRowClick?: (evt: React.MouseEvent, row: T) => void;
  loading?: boolean;
  hasMore?: boolean;
  onSkip?: () => void;
  rowKey?: keyof T;
  errorMessage?: string;
  rowStyle?: (row: T) => React.CSSProperties | undefined;
}

const typedMemo: <T>(c: T) => T = React.memo;

export const InfiniteTable = typedMemo(
  // eslint-disable-next-line @typescript-eslint/ban-types
  <T extends object>({
    data,
    columns,
    onTableRowClick,
    loading,
    hasMore,
    onSkip,
    rowKey,
    errorMessage,
    rowStyle,
  }: IProps<T>) => {
    const classes = useStyles();

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) {
          observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            if (onSkip) {
              onSkip();
            }
          }
        });
        if (node) {
          observer.current.observe(node);
        }
      },
      [loading, hasMore, onSkip],
    );

    return (
      <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.label}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
            <tr>
              <td>
                {loading && <LinearProgress className={classes.progress} />}
              </td>
            </tr>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                hover
                className={cx({
                  [classes.rowOnClick]: Boolean(onTableRowClick),
                })}
                style={rowStyle && rowStyle(row)}
                onClick={
                  onTableRowClick
                    ? (evt) => {
                        onTableRowClick(evt, row);
                      }
                    : undefined
                }
                ref={data.length === index + 1 ? lastElementRef : null}
                key={`row-${rowKey ? row[rowKey] : index}`}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.label}
                    align={column.align}
                    onClick={
                      column.stopPropagation
                        ? (evt) => {
                            evt.stopPropagation();
                          }
                        : undefined
                    }
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {column.format
                      ? column.format(row)
                      : column.field
                      ? row[column.field]
                      : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!loading && !errorMessage && data.length === 0 && (
          <div className={classes.noData}>
            <Typography variant="body1">No data</Typography>
          </div>
        )}
        {errorMessage && (
          <div className={classes.noData}>
            <Typography color="error" variant="body1">
              {errorMessage}
            </Typography>
          </div>
        )}
      </TableContainer>
    );
  },
);
