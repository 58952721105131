import React from 'react';
import {
  Typography,
  Input,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'wouter';

import { createWithdrawal, ICreateWithdrawal } from '../../api/finance.api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const CreateWithdrawal = () => {
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const formik = useFormik<ICreateWithdrawal>({
    initialValues: {
      amount: '',
    },
    onSubmit: async (values) => {
      const { ok } = await createWithdrawal(values);
      if (ok) {
        setLocation(`/withdrawals`);
      }
    },
  });
  return (
    <div>
      <Typography gutterBottom variant="h4">
        Create Withdrawal
      </Typography>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.form}>
              <Input
                id="amount"
                placeholder="Amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
              />
            </div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
