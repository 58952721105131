import { create } from 'zustand';

import { IMetadata } from '../api/utilities.api';

interface IUseMetadata {
  metadata?: IMetadata;
  setMetadata: (metadata?: IMetadata) => void;
}

export const [useMetadata] = create<IUseMetadata>((set) => ({
  metadata: undefined,
  setMetadata(metadata?: IMetadata) {
    set({ metadata });
  },
}));
