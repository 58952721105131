import React, { memo, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { Link, RouteComponentProps } from 'wouter';
import { formatDistanceToNow } from 'date-fns';

import { getOrderByOrderId, IOrder } from '../../api/orders.api';
import { Loader } from '../Loader';
import { toLocaleString } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  orderId: {
    marginLeft: theme.spacing(2),
  },
}));

export const Order = memo(({ params }: RouteComponentProps) => {
  const classes = useStyles();

  const [order, setOrder] = useState<IOrder>();
  const [loading, setLoading] = useState(false);

  const fetchOrder = useCallback(async () => {
    setLoading(true);
    const { data } = await getOrderByOrderId(params.orderId);
    if (data) {
      setOrder(data);
    }
    setLoading(false);
  }, [params.orderId]);

  useEffect(() => {
    void fetchOrder();
  }, [fetchOrder]);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography gutterBottom variant="h4">
          Order
        </Typography>
        <Typography className={classes.orderId} variant="subtitle1">
          {order?.order_id}
        </Typography>
      </div>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <Loader loading={loading}>
            {order && (
              <>
                <p>
                  <strong>Created At: </strong>
                  {new Date(order.created_at).toLocaleString()}
                  <br />
                  {formatDistanceToNow(new Date(order.created_at), {
                    addSuffix: true,
                  })}
                </p>
                <p>
                  <strong>Status: </strong>
                  {order.status}
                </p>
                <p>
                  <strong>Fiat currency: </strong>
                  {toLocaleString(order.fiat_currency_amount)}{' '}
                  {order.fiat_currency}
                </p>
                <p>
                  <strong>Cryptocurrency: </strong>
                  {Number(order.cryptocurrency_amount)} {order.cryptocurrency}
                </p>
                <p>
                  <strong>Partner payout amount: </strong>
                  {order.partner_payout_usd_amount} USD
                </p>
                <p>
                  <strong>Partner Order Id: </strong>
                  {order.partner_order_id}
                </p>
                <p>
                  <strong>Order Id: </strong>
                  {order.order_id}
                </p>
                <p>
                  <strong>Customer Id: </strong>
                  <Link href={`/customer/${order.customer_id}`}>
                    {order.customer_id}
                  </Link>
                </p>
                <p>
                  <strong>Expires At: </strong>
                  {order.expires_at
                    ? new Date(order.expires_at).toLocaleString()
                    : '—'}
                </p>
                <p>
                  <strong>Customer changed payout address: </strong>
                  {order.customer_changed_payout_address.toString()}
                </p>
                <p>
                  <strong>Payout address: </strong>
                  {order.payout_address}
                </p>
                <p>
                  <strong>Payform url: </strong>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={order.payform_url}
                  >
                    {order.payform_url}
                  </a>
                </p>
                <p>
                  <strong>Error details: </strong>
                  {order.error_details}
                </p>
              </>
            )}
          </Loader>
        </CardContent>
      </Card>
    </div>
  );
});
