import React, { memo } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface IProps extends ButtonProps {
  loading: boolean;
}

export const LoadingButton = memo(({ loading, ...rest }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Button disabled={loading} {...rest} />
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
});
