import React, { useCallback, useState } from 'react';
import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { useKey } from 'react-use';
import { useLocation } from 'wouter';

import { signIn as signInApi } from '../../api/auth.api';
import { useMetadata } from '../../hooks/useMetadata';
import { getMetadata } from '../../api/utilities.api';

const styles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute' as const,
    left: 0,
    width: '100%',
    height: '70%',
  },
  title: {
    fontSize: '28px',
    lineHeight: '40px',
    letterSpacing: '-1px',
    marginBottom: '1rem',
  },
  textField: {
    width: '100%',
  },
  form: {
    width: '330px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
  },
  signIn: {
    width: '130px',
  },
});

export const Login = () => {
  const classes = styles();
  const [, setLocation] = useLocation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onUsernameChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(evt.target.value);
    },
    [],
  );

  const onPasswordChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(evt.target.value);
    },
    [],
  );

  const setMetadata = useMetadata((s) => s.setMetadata);

  const fetchMetadata = useCallback(async () => {
    const { data } = await getMetadata();
    setMetadata(data);
  }, [setMetadata]);

  const signIn = useCallback(async () => {
    const { ok, error } = await signInApi(username, password);
    if (ok) {
      void fetchMetadata();
      setLocation('/');
    }
    if (error) {
      setErrorMessage(error.message);
    }
  }, [username, password, setLocation, fetchMetadata]);

  useKey(
    'Enter',
    () => {
      void signIn();
    },
    undefined,
    [signIn],
  );

  return (
    <div className={classes.wrapper}>
      <form className={classes.form}>
        <h2 className={classes.title}>Welcome!</h2>
        <TextField
          error={Boolean(errorMessage)}
          label="Username"
          className={classes.textField}
          onChange={onUsernameChange}
          margin="normal"
          variant="outlined"
          autoComplete="username"
          type="email"
        />
        <TextField
          error={Boolean(errorMessage)}
          label="Password"
          className={classes.textField}
          onChange={onPasswordChange}
          type="password"
          margin="normal"
          variant="outlined"
          autoComplete="current-password"
        />
        {errorMessage && (
          <Typography color="secondary">{errorMessage}</Typography>
        )}
        <div className={classes.actions}>
          <Button
            className={classes.signIn}
            variant="contained"
            color="primary"
            onClick={signIn}
          >
            SIGN IN
          </Button>
        </div>
      </form>
    </div>
  );
};
