export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
}

export function capitalizeEveryWord(sentence: string) {
  return sentence
    .split('_')
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(' ');
}

export function toLocaleString(value: string) {
  return Number(value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const orderIdPattern = /cspro_[\da-z]{30}$/;
export function isOrderId(value: string) {
  return orderIdPattern.test(value);
}

const customerIdPattern = /cscst_[\da-z]{30}$/;
export function isCustomerId(value: string) {
  return customerIdPattern.test(value);
}

const emailPattern = /\S+@\S+\.\S+/;
export function isEmail(email: string) {
  return emailPattern.test(email);
}

export function downloadFile(data: BlobPart, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
