import { request } from './request';

export interface ILedger {
  record_id: string;
  record_type: 'debit' | 'credit';
  memo: string;
  amount: string;
  created_at: string;
  withdrawal_id?: string;
  order_id?: string;
  external_partner_order_id?: string;
  order_fiat_currency_amount?: string;
  cs_kyc_customer_id?: string;
}

export interface IWithdrawal {
  withdrawal_id: string;
  amount: string;
  status: string;
  created_at: string;
  updated_at?: string;
  completed_at?: string;
}

export interface ICreateWithdrawal {
  amount: string;
}

interface IGetLedger {
  limit?: number;
  skip?: number;
  from_date?: string;
  to_date?: string;
}

interface IDownloadLedgerCSV {
  limit?: number;
  skip?: number;
  from_date: string;
  to_date: string;
}

export async function getLedger({
  limit,
  skip,
  from_date,
  to_date,
}: IGetLedger) {
  return request<Array<ILedger>>('get', 'v1/ledger', {
    limit,
    skip,
    from_date,
    to_date,
  });
}

export async function downloadCSV({
  limit,
  skip,
  from_date,
  to_date,
}: IDownloadLedgerCSV) {
  return request(
    'get',
    'v1/ledger/csv',
    {
      limit,
      skip,
      from_date,
      to_date,
    },
    {
      responseType: 'blob',
    },
  );
}

export async function getWithdrawals(limit?: number, skip?: number) {
  return request<Array<IWithdrawal>>('get', 'v1/withdrawals', {
    limit,
    skip,
  });
}

export async function getWithdrawalById(withdrawalId: string) {
  return request<IWithdrawal>('get', `v1/withdrawal/${withdrawalId}`);
}

export async function createWithdrawal(data: ICreateWithdrawal) {
  return request<IWithdrawal>('post', `v1/withdrawal`, data);
}
