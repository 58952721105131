import { request } from './request';

export interface ICustomer {
  customer_id: string;
  customer_email: string;
  customer_external_id: string;
  missing_kyc: Array<string>;
  created_at: string;
  updated_at: string;
}

export interface IDocument {
  document_id: string;
  kyc_request_type: 'identity' | 'proof_of_address' | 'proof_of_funds';
  document_type: string;
  total_files: number;
  files: number;
  status: string;
}

export interface ICreateCustomerDocument {
  kyc_request_type: 'identity' | 'proof_of_address' | 'proof_of_funds';
  document_type: string;
  total_files: number;
}

export interface IFile {
  file_id: string;
  document_id: string;
  file_name: string;
  mime_type: string;
  uploaded_at: string;
}

export async function getCustomers(limit?: number, skip?: number) {
  return request<Array<ICustomer>>('get', 'v1/customers', {
    limit,
    skip,
  });
}

export async function getCustomerByCustomerId(customerId: string) {
  return request<ICustomer>('get', `v1/customer/${customerId}`);
}

export async function getCustomerByExternalId(externalId: string) {
  return request<ICustomer>('get', `v1/customer_by_external_id/${externalId}`);
}

export async function getCustomerByEmail(email: string) {
  return request<ICustomer>('get', `v1/customer_by_email/${email}`);
}

export async function getCustomerDocumentsByCustomerId(customerId: string) {
  return request<Array<IDocument>>(
    'get',
    `v1/customer/${customerId}/documents`,
  );
}

export async function createCustomerDocumentByCustomerId(
  customerId: string,
  data: ICreateCustomerDocument,
) {
  return request<IDocument>(
    'post',
    `v1/customer/${customerId}/customer_document`,
    data,
  );
}

export async function getCustomerDocument(
  customerId: string,
  documentId: string,
) {
  return request<IDocument>(
    'get',
    `v1/customer/${customerId}/document/${documentId}`,
  );
}

export async function getCustomerDocumentFiles(
  customerId: string,
  documentId: string,
) {
  return request<Array<IFile>>(
    'get',
    `v1/customer/${customerId}/document/${documentId}/files`,
  );
}

export async function getCustomerDocumentFile(
  customerId: string,
  documentId: string,
  fileId: string,
) {
  return request(
    'get',
    `v1/customer/${customerId}/document/${documentId}/file/${fileId}`,
    undefined,
    {
      responseType: 'blob',
    },
  );
}

export async function uploadCustomerDocument(
  customerId: string,
  documentId: string,
  file: FormData,
) {
  return request<IDocument>(
    'post',
    `v1/customer/${customerId}/document/${documentId}/file`,
    file,
  );
}
