import React from 'react';
import { Switch, useLocation, RouteProps, Redirect, Route } from 'wouter';

import { Layout } from './components/Layout';
import { Login } from './components/Login';
import { Orders, Order, CreateOrder } from './components/Orders';
import { Customer, Customers } from './components/Customers';
import { CreateDocument, Document } from './components/Documents';
import { Ledger } from './components/Ledger';
import {
  CreateWithdrawal,
  Withdrawal,
  Withdrawals,
} from './components/Withdrawals';

const PrivateRoute = (routeProps: RouteProps) => {
  const [location] = useLocation();

  if (localStorage.getItem('token')) {
    if (location === '/login') {
      return <Redirect to="/orders" />;
    }

    if (location === '/') {
      return <Redirect to="/orders" />;
    }

    return <Route {...routeProps} />;
  }

  if (location === '/login') {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/login" />;
};

const App = () => {
  return (
    <Layout>
      <Switch>
        <PrivateRoute path="/" />
        <PrivateRoute path="/login" component={Login} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/order/create" component={CreateOrder} />
        <PrivateRoute path="/order/:orderId" component={Order} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/customer/:customerId" component={Customer} />
        <PrivateRoute
          path="/customer/:customerId/document/create"
          component={CreateDocument}
        />
        <PrivateRoute
          path="/customer/:customerId/document/:documentId"
          component={Document}
        />
        <PrivateRoute path="/operations" component={Ledger} />
        <PrivateRoute path="/withdrawals" component={Withdrawals} />
        <PrivateRoute path="/withdrawal/create" component={CreateWithdrawal} />
        <PrivateRoute path="/withdrawal/:withdrawalId" component={Withdrawal} />
      </Switch>
    </Layout>
  );
};

export default App;
