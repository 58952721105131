import { request } from './request';

export interface IMetadata {
  partner_name: string;
  total_amount_usd_awaiting_for_kyc: string;
  total_withdrawable_amount_usd: string;
  test: boolean;
}

export async function getMetadata() {
  return request<IMetadata>('get', 'v1/metadata');
}

export async function suggestCustomerEmails(value: string) {
  return request<Array<string>>('get', 'v1/suggest_customer_emails', {
    customer_email: value,
  });
}

export async function getCryptocurrencies() {
  return request<Array<string>>('get', 'v1/cryptocurrencies');
}
