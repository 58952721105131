import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps, useLocation } from 'wouter';
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import { useUpdateEffect } from 'react-use';
import AddIcon from '@material-ui/icons/Add';

import {
  getCustomerByCustomerId,
  getCustomerDocumentsByCustomerId,
  ICustomer,
  IDocument,
} from '../../api/customers.api';
import { IOrder, getCustomerOrdersByCustomerId } from '../../api/orders.api';
import { IColumn, InfiniteTable } from '../InfiniteTable';
import { getOrdersColumns, getRowColor } from '../Orders';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'baseline',
  },
  customerId: {
    marginLeft: theme.spacing(2),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  documentsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
}));

const documentsColumns: Array<IColumn<IDocument>> = [
  {
    label: 'Document Id',
    field: 'document_id',
  },
  {
    label: 'Kyc Request Type',
    field: 'kyc_request_type',
  },
  {
    label: 'Document Type',
    field: 'document_type',
  },
  {
    label: 'Total Files',
    field: 'total_files',
  },
  {
    label: 'Files',
    field: 'files',
  },
  {
    label: 'Status',
    field: 'status',
  },
];

const LIMIT = 20;

export const Customer = memo(({ params }: RouteComponentProps) => {
  const classes = useStyles();

  const [ordersLoading, setOrdersLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const [customer, setCustomer] = useState<ICustomer>();
  const [documents, setDocuments] = useState<Array<IDocument>>([]);
  const [orders, setOrders] = useState<Array<IOrder>>([]);
  const [, setLocation] = useLocation();

  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);

  const onSkip = useCallback(() => {
    setSkip((s) => s + LIMIT);
  }, []);

  const fetchCustomer = useCallback(async () => {
    const { data } = await getCustomerByCustomerId(params.customerId);
    if (data) {
      setCustomer(data);
    }
  }, [params.customerId]);

  const fetchDocuments = useCallback(async () => {
    setDocumentsLoading(true);
    const { data } = await getCustomerDocumentsByCustomerId(params.customerId);
    if (data) {
      setDocuments(data);
    }
    setDocumentsLoading(false);
  }, [params.customerId]);

  const fetchOrders = useCallback(
    async (skipOrders: number) => {
      setOrdersLoading(true);
      const { data } = await getCustomerOrdersByCustomerId(
        params.customerId,
        LIMIT,
        skipOrders,
      );
      if (data) {
        if (data.length > 0) {
          setOrders((s) => [...s, ...data]);
        } else {
          setHasMore(false);
        }
      }
      setOrdersLoading(false);
    },
    [params.customerId],
  );

  useEffect(() => {
    void fetchCustomer();
  }, [fetchCustomer]);

  useEffect(() => {
    void fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    void fetchOrders(0);
  }, [fetchOrders]);

  useUpdateEffect(() => {
    void fetchOrders(skip);
  }, [fetchOrders, skip]);

  const ordersColumns = useMemo(() => getOrdersColumns(classes), [classes]);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography gutterBottom variant="h4">
          Customer
        </Typography>
        <Typography className={classes.customerId} variant="subtitle1">
          {customer?.customer_id}
        </Typography>
      </div>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          {customer && (
            <>
              <p>
                <strong>Customer Email: </strong>
                {customer.customer_email}
              </p>
              <p>
                <strong>Missing Kyc: </strong>
                {customer.missing_kyc.join(', ')}
              </p>
              <p>
                <strong>Customer Id: </strong>
                {customer.customer_id}
              </p>
              <p>
                <strong>Customer External Id: </strong>
                {customer.customer_external_id}
              </p>
              <p>
                <strong>Created At: </strong>
                {new Date(customer.created_at).toLocaleString()}
                <br />
                {formatDistanceToNow(new Date(customer.created_at), {
                  addSuffix: true,
                })}
              </p>
              <p>
                <strong>Updated At: </strong>
                {new Date(customer.updated_at).toLocaleString()}
              </p>
            </>
          )}
        </CardContent>
      </Card>
      <div style={{ marginBottom: 20 }}>
        <div className={classes.documentsTitle}>
          <Typography gutterBottom variant="h5">
            Documents
          </Typography>
          <Link href={`/customer/${params.customerId}/document/create`}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add document
            </Button>
          </Link>
        </div>
        <InfiniteTable
          data={documents}
          columns={documentsColumns}
          loading={documentsLoading}
          rowKey="document_id"
          onTableRowClick={(_, row) => {
            setLocation(
              `/customer/${params.customerId}/document/${row?.document_id}`,
            );
          }}
        />
      </div>
      <div>
        <Typography gutterBottom variant="h5">
          Orders
        </Typography>
        <InfiniteTable
          data={orders}
          columns={ordersColumns}
          loading={ordersLoading}
          rowKey="order_id"
          onSkip={onSkip}
          hasMore={hasMore}
          onTableRowClick={(_, rowData) => {
            setLocation(`/order/${rowData?.order_id}`);
          }}
          rowStyle={(row) => ({
            borderLeft: `10px solid ${getRowColor(row.status)}`,
          })}
        />
      </div>
    </div>
  );
});
