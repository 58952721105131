import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { config } from '../config';
import { logout } from '../helpers';

type HTTPMethods = 'post' | 'get' | 'put' | 'patch' | 'delete';

export interface IResponse<T> {
  error?: any;
  ok: boolean;
  data?: T;
  response?: AxiosResponse<T>;
}

const apiUrl =
  config.apiUrl?.slice(-1) === '/' ? config.apiUrl.slice(0, -1) : config.apiUrl;

export async function request<T = any>(
  method: HTTPMethods,
  endpoint: string,
  params?: any,
  options = {},
): Promise<IResponse<T>> {
  const data: {
    params?: any;
    data?: any;
  } = {};
  if (method.toLowerCase() === 'get') {
    data.params = params;
  } else {
    data.data = params;
  }

  const headers: any = {};

  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = token;
  }

  return axios(`${apiUrl}/${endpoint}`, {
    method,
    headers,
    ...data,
    ...options,
  })
    .then((response) => ({
      ok: true,
      data: response.data,
      response,
    }))
    .catch((error) => {
      console.error('request_error: ', get(error, 'response.data'));
      if (get(error, 'response.data.error_code') === 'JWT_VERIFY_ERROR') {
        logout();
        window.location.pathname = '/login';
      }
      return {
        ok: false,
        error,
      };
    });
}
