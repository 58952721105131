import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ButtonBase,
  Button,
  Avatar,
  Divider,
  Snackbar,
  SnackbarContent,
  IconButton,
  Menu,
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import MoneyIcon from '@material-ui/icons/Money';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation, Link } from 'wouter';
import cx from 'classnames';
import { useInterval } from 'react-use';

import Logo from '../../assets/logo.png';
import { getMetadata } from '../../api/utilities.api';
import { useMetadata } from '../../hooks/useMetadata';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  logoContainer: {
    marginRight: theme.spacing(2),
    width: 48,
    height: 48,
    cursor: 'pointer',
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
  avatar: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  purpleAvatar: {
    margin: theme.spacing(1),
    color: '#fff',
    backgroundColor: theme.palette.info.main,
  },
  meta: {
    padding: theme.spacing(2),
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  title: {
    flexGrow: 1,
  },
}));

const INTERVAL_TIME = 10 * 1000;

export const Layout = memo(({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles();
  const [location, setLocation] = useLocation();

  const getEmail = useCallback(() => localStorage.getItem('email'), []);

  const { metadata, setMetadata } = useMetadata();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const intervalTime = useMemo(() => {
    if (metadata) {
      return INTERVAL_TIME;
    }
    return null;
  }, [metadata]);

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const fetchMetadata = useCallback(async () => {
    const { data } = await getMetadata();
    setMetadata(data);
  }, [setMetadata]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      void fetchMetadata();
    }
  }, [fetchMetadata]);

  useInterval(() => {
    if (localStorage.getItem('token')) {
      void fetchMetadata();
    }
  }, intervalTime);

  const signOut = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    setLocation('/login');
    setMetadata(undefined);
    setAnchorEl(null);
  }, [setLocation, setMetadata]);

  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();

  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link href="/">
            <div className={classes.logoContainer}>
              <img className={classes.logoImg} src={Logo} alt="logo" />
            </div>
          </Link>
          <Typography variant="h6" className={classes.title}>
            Cryptoshop
          </Typography>

          {getEmail() && (
            <div>
              <ButtonBase
                type="button"
                onClick={handleMenu}
                className={classes.avatar}
              >
                <Avatar className={classes.purpleAvatar}>
                  {getEmail()?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography>{getEmail()}</Typography>
              </ButtonBase>
              <Menu
                open={Boolean(anchorEl)}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose}
              >
                <Button
                  style={{
                    fontSize: '16px',
                  }}
                  onClick={signOut}
                >
                  <ExitToAppIcon style={{ marginRight: '6px' }} />
                  Sign Out
                </Button>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <List>
          <ListItem
            button
            selected={location.includes('/orders')}
            onClick={() => setLocation('/orders')}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
          <ListItem
            button
            selected={location.includes('/customers')}
            onClick={() => setLocation('/customers')}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
          <ListItem
            button
            selected={location.includes('/operations')}
            onClick={() => setLocation('/operations')}
          >
            <ListItemIcon>
              <ImportContactsIcon />
            </ListItemIcon>
            <ListItemText primary="Operations" />
          </ListItem>
          <ListItem
            button
            selected={location.includes('/withdrawals')}
            onClick={() => setLocation('/withdrawals')}
          >
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Withdrawals" />
          </ListItem>
        </List>
        <Divider />
        {metadata && (
          <Typography className={classes.meta} variant="caption">
            <p>
              Partner name: <strong>{metadata?.partner_name}</strong>
            </p>
            <p>
              Total amount awaiting for KYC:{' '}
              <strong>{metadata?.total_amount_usd_awaiting_for_kyc} USD</strong>
            </p>
            <p>
              Total withdrawable amount:{' '}
              <strong>{metadata?.total_withdrawable_amount_usd} USD</strong>
            </p>
            {metadata.test && (
              <p>
                <strong>Testing account</strong>
              </p>
            )}
          </Typography>
        )}
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
});
