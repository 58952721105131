import create from 'zustand';

export interface IUseSnackbar {
  snackbarValue: string;
  snackbarType: SnackbarType;
  setSnackbarValue: (value: string, snackbarType?: SnackbarType) => void;
}

export enum SnackbarType {
  Default = 0,
  Error = 1,
}

export const [useSnackbar] = create<IUseSnackbar>((set) => ({
  snackbarValue: '',
  snackbarType: SnackbarType.Default,
  setSnackbarValue: (
    snackbarValue: string,
    snackbarType = SnackbarType.Default,
  ) => set({ snackbarValue, snackbarType }),
}));
