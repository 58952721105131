import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Modal,
  Backdrop,
  BackdropProps,
  Button,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { RouteComponentProps } from 'wouter';
import { useKey } from 'react-use';
import cx from 'classnames';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {
  getCustomerDocument,
  getCustomerDocumentFile,
  getCustomerDocumentFiles,
  IDocument,
  IFile,
  uploadCustomerDocument,
} from '../../api/customers.api';
import { Loader } from '../Loader';

interface IFileWithPreview extends IFile {
  preview_url?: string;
}

const useStyles = makeStyles((theme) => ({
  filesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileImg: {
    width: 400,
  },
  card: {
    marginRight: theme.spacing(3),
    cursor: 'pointer',
  },
  cardContent: {
    position: 'relative',
    '&:hover': {
      '@global': {
        '.download-file': {
          display: 'block',
        },
      },
    },
  },
  '@global': {
    '.download-file': {
      display: 'none',
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(4),
      zIndex: 10,
      padding: theme.spacing(1),
      opacity: 0.5,
      background: 'none',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',

      '&:hover': {
        opacity: 1,
      },
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalContent: {
    width: 'calc(1.25 * 100vh)',
    outline: 0,
  },

  modalImg: {
    width: '100%',
  },

  modalDescription: {
    color: '#fff',
  },

  keyboardArrowWrapper: {
    display: 'flex',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute' as const,
    top: '50%',
    cursor: 'pointer',
    width: 40,
    height: 40,
    background: '#0A0A0A',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  keyboardArrowWrapperLeft: {
    left: 15,
  },
  keyboardArrowWrapperRight: {
    right: 20,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

export const Document = memo(({ params }: RouteComponentProps) => {
  const classes = useStyles();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [document, setDocument] = useState<IDocument>();
  const [files, setFiles] = useState<Array<IFileWithPreview>>([]);
  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const goForwardFile = useCallback(() => {
    if (isModalOpen) {
      setCurrentFileIndex((s) => {
        if (s + 1 === files.length) {
          return 0;
        }
        return s + 1;
      });
    }
  }, [files, isModalOpen]);

  const goBackwardFile = useCallback(() => {
    if (isModalOpen) {
      setCurrentFileIndex((s) => {
        if (s - 1 < 0) {
          return files.length - 1;
        }
        return s - 1;
      });
    }
  }, [files.length, isModalOpen]);

  useKey('ArrowRight', goForwardFile, undefined, [goForwardFile]);
  useKey('ArrowLeft', goBackwardFile, undefined, [goBackwardFile]);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onPreviewClick = useCallback(
    (fileId: string) => {
      const index = files.findIndex((f) => f.file_id === fileId);
      if (index !== -1) {
        setCurrentFileIndex(index);
      }
      setIsModalOpen(true);
    },
    [files],
  );

  const fetchDocument = useCallback(async () => {
    const { data } = await getCustomerDocument(
      params.customerId,
      params.documentId,
    );

    if (data) {
      setDocument(data);
    }
  }, [params.customerId, params.documentId]);

  const fetchFiles = useCallback(async () => {
    setIsFilesLoading(true);
    const { data } = await getCustomerDocumentFiles(
      params.customerId,
      params.documentId,
    );

    if (data) {
      const resultFiles = await Promise.all(
        data.map(async (file) => {
          const response = await getCustomerDocumentFile(
            params.customerId,
            params.documentId,
            file.file_id,
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          return {
            ...file,
            preview_url: url,
          };
        }),
      );

      setFiles(resultFiles);
    }
    setIsFilesLoading(false);
  }, [params.customerId, params.documentId]);

  useEffect(() => {
    void fetchDocument();
  }, [fetchDocument]);

  useEffect(() => {
    void fetchFiles();
  }, [fetchFiles]);

  const downloadFile = useCallback(
    async (fileId: string) => {
      const file = files.find((f) => f.file_id === fileId);
      if (file && file.preview_url) {
        const link = window.document.createElement('a');
        link.href = file.preview_url;
        link.setAttribute('download', file.file_name);
        window.document.body.appendChild(link);
        link.click();
      }
    },
    [files],
  );

  const getBackdropComponent = useCallback(
    (props: BackdropProps) => (
      <>
        <div
          className={cx(
            classes.keyboardArrowWrapper,
            classes.keyboardArrowWrapperLeft,
          )}
        >
          <KeyboardArrowLeftIcon onClick={() => goBackwardFile()} />
        </div>
        <div
          className={cx(
            classes.keyboardArrowWrapper,
            classes.keyboardArrowWrapperRight,
          )}
        >
          <KeyboardArrowRightIcon onClick={() => goForwardFile()} />
        </div>
        <Backdrop {...props} transitionDuration={0} />
      </>
    ),
    [classes, goBackwardFile, goForwardFile],
  );

  const onFileUpload = useCallback(
    async (evt: ChangeEvent<any>) => {
      setIsFilesLoading(true);
      const fd = new FormData();
      fd.append('file', evt.target.files[0]);
      await uploadCustomerDocument(params.customerId, params.documentId, fd);
      if (fileUploadRef.current) {
        fileUploadRef.current.value = '';
      }
      await fetchFiles();
      setIsFilesLoading(false);
    },
    [params.customerId, params.documentId, fetchFiles],
  );

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography gutterBottom variant="h4">
          Document
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={() => {
            if (fileUploadRef.current) {
              fileUploadRef.current.click();
            }
          }}
        >
          Upload file
        </Button>
      </div>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          {document && (
            <>
              <p>
                <strong>Document Type: </strong>
                {document.document_type}
              </p>
              <p>
                <strong>Kyc Request Type: </strong>
                {document.kyc_request_type}
              </p>
              <p>
                <strong>Status: </strong>
                {document.status}
              </p>
              <p>
                <strong>Files: </strong>
                {document.files}
              </p>
              <p>
                <strong>Total Files: </strong>
                {document.total_files}
              </p>
              <p>
                <strong>Document Id: </strong>
                {document.document_id}
              </p>
            </>
          )}
        </CardContent>
      </Card>
      <Loader loading={isFilesLoading}>
        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <Typography gutterBottom variant="h6">
              Files
            </Typography>
            <div className={classes.filesWrapper}>
              {files.map((f) => (
                <Card
                  key={f.file_id}
                  className={classes.card}
                  onClick={() => {
                    onPreviewClick(f.file_id);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <button
                      className="download-file"
                      type="button"
                      onClick={(evt) => {
                        evt.stopPropagation();
                        void downloadFile(f.file_id);
                      }}
                    >
                      <CloudDownloadIcon htmlColor="#fff" fontSize="large" />
                    </button>
                    <img
                      className={classes.fileImg}
                      src={f.preview_url}
                      alt=""
                    />
                    <Typography variant="subtitle2">{f.file_name}</Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>
      </Loader>
      <Modal
        open={isModalOpen}
        onClose={onModalClose}
        className={classes.modal}
        BackdropComponent={getBackdropComponent}
      >
        <div className={classes.modalContent}>
          <img
            className={classes.modalImg}
            src={files[currentFileIndex]?.preview_url}
            alt=""
          />
          <Typography className={classes.modalDescription} variant="body1">
            {files[currentFileIndex]?.file_name}
          </Typography>
        </div>
      </Modal>
      <input
        type="file"
        onChange={onFileUpload}
        ref={fileUploadRef}
        style={{ display: 'none' }}
      />
    </div>
  );
});
