import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'wouter';
import { useDebounce } from 'react-use';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { omitBy } from 'lodash';

import {
  createOrder,
  getOrderParams,
  ICreateOrderBody,
  IOrderParams,
} from '../../api/orders.api';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import {
  getCryptocurrencies,
  suggestCustomerEmails,
} from '../../api/utilities.api';
import { isEmail } from '../../helpers';
import { LoadingButton } from '../LoadingButton';

const useStyles = makeStyles((theme) => ({
  form: {},
  textField: {
    display: 'block',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: '50ch',
  },
  info: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  input: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  infoWrapper: {
    height: 80,
  },
}));

export const CreateOrder = () => {
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [orderParams, setOrderParams] = useState<IOrderParams>();

  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState<Array<string>>([]);
  const [emailsLoading, setEmailsLoading] = useState(false);
  const [emailsOpen, setEmailsOpen] = useState(false);

  const [cryptocurrencies, setCryptocurrencies] = useState<Array<string>>([]);

  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);

  const formik = useFormik<ICreateOrderBody>({
    initialValues: {
      cryptocurrency: 'LTC',
      fiat_currency: 'USD',
      fiat_currency_amount: '',
      customer_email: '',
      customer_external_id: '',
      partner_order_id: '',
      customer_name: '',
      customer_country_alpha3: '',
      customer_usa_state: '',
      customer_city: '',
      customer_address: '',
      customer_zip_code: '',
      customer_phone: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      const { data, error } = await createOrder({
        ...(omitBy(values, (v) => !v) as ICreateOrderBody),
        fiat_currency_amount: Number(values.fiat_currency_amount).toFixed(2),
      });
      if (data) {
        setLoading(false);
        setLocation(`/order/${data.order_id}`);
      }
      if (error) {
        setLoading(false);
        setSnackbarValue(
          error.response?.data?.error_details,
          SnackbarType.Error,
        );
      }
    },
  });

  const fetchCryptocurrencies = useCallback(async () => {
    const { data } = await getCryptocurrencies();

    if (data) {
      setCryptocurrencies(data);
    }
  }, []);

  useEffect(() => {
    void fetchCryptocurrencies();
  }, [fetchCryptocurrencies]);

  const fetchEmails = useCallback(async (value: string) => {
    setEmailsLoading(true);

    const { data } = await suggestCustomerEmails(value);

    if (data) {
      setEmails(data);
      setEmailsOpen(true);
    }

    setEmailsLoading(false);
  }, []);

  useDebounce(
    () => {
      if (formik.values.customer_email) {
        if (!isEmail(formik.values.customer_email)) {
          void fetchEmails(formik.values.customer_email);
        }
      }
    },
    300,
    [formik.values.customer_email],
  );

  const fetchOrderParams = useCallback(async () => {
    if (
      formik.values.cryptocurrency &&
      formik.values.fiat_currency &&
      formik.values.fiat_currency_amount
    ) {
      const { data, error } = await getOrderParams({
        cryptocurrency: formik.values.cryptocurrency,
        fiat_currency: formik.values.fiat_currency,
        fiat_currency_amount: formik.values.fiat_currency_amount,
      });

      if (data) {
        setErrorMessage('');
        setOrderParams(data);
      }

      if (error) {
        setOrderParams(undefined);
        setErrorMessage(error.response?.data?.error_details);
      }
    }
  }, [formik.values]);

  useDebounce(
    () => {
      void fetchOrderParams();
    },
    200,
    [fetchOrderParams],
  );

  return (
    <div>
      <Typography gutterBottom variant="h4">
        Create Order
      </Typography>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.form}>
              <Autocomplete
                id="cryptocurrency"
                getOptionSelected={(option, value) => option === value}
                options={cryptocurrencies}
                onChange={(_, value) => {
                  formik.setFieldValue('cryptocurrency', value);
                }}
                freeSolo
                value={formik.values.cryptocurrency}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    value={formik.values.cryptocurrency}
                    onChange={formik.handleChange}
                    label="Cryptocurrency"
                    placeholder="ETH"
                    required
                    InputProps={{
                      ...params.InputProps,
                      className: classes.input,
                    }}
                  />
                )}
              />

              <Autocomplete
                id="fiat_currency"
                getOptionSelected={(option, value) => option === value}
                options={['USD', 'EUR']}
                onChange={(_, value) => {
                  formik.setFieldValue('fiat_currency', value);
                }}
                freeSolo
                value={formik.values.fiat_currency}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fiat currency"
                    placeholder="USD"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.fiat_currency}
                    className={classes.textField}
                    InputProps={{
                      ...params.InputProps,
                      className: classes.input,
                    }}
                  />
                )}
              />
              <TextField
                id="fiat_currency_amount"
                required
                label="Fiat currency amount"
                placeholder="1000"
                onChange={formik.handleChange}
                value={formik.values.fiat_currency_amount}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <Autocomplete
                id="customer_email"
                getOptionSelected={(option, value) => option === value}
                options={emails}
                open={emailsOpen}
                onClose={() => {
                  setEmailsOpen(false);
                }}
                onChange={(_, value) => {
                  formik.setFieldValue('customer_email', value);
                }}
                value={formik.values.customer_email}
                loading={emailsLoading}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer email"
                    placeholder="johndoe@gmail.com"
                    value={formik.values.customer_email}
                    onChange={formik.handleChange}
                    className={classes.textField}
                    required
                    InputProps={{
                      ...params.InputProps,
                      className: classes.input,
                      endAdornment: emailsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null,
                    }}
                  />
                )}
              />
              <div className={classes.infoWrapper}>
                <Typography className={classes.info} variant="body1">
                  Cryptocurrency amount:{' '}
                  {orderParams ? orderParams.cryptocurrency_amount : '—'}{' '}
                  {formik.values.cryptocurrency}
                </Typography>
                <Typography className={classes.info} variant="body1">
                  Payout amount: {orderParams ? orderParams.usd_amount : '—'}{' '}
                  USD
                </Typography>
                <Typography
                  className={classes.info}
                  variant="body1"
                  color="error"
                >
                  {errorMessage}
                </Typography>
              </div>
              <Divider />
              <TextField
                id="customer_external_id"
                label="Customer external ID"
                onChange={formik.handleChange}
                value={formik.values.customer_external_id}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="partner_order_id"
                label="Partner order ID"
                onChange={formik.handleChange}
                value={formik.values.partner_order_id}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_name"
                label="Customer name"
                onChange={formik.handleChange}
                value={formik.values.customer_name}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_country_alpha3"
                label="Customer country alpha3"
                onChange={formik.handleChange}
                value={formik.values.customer_country_alpha3}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_usa_state"
                label="Customer USA state"
                onChange={formik.handleChange}
                value={formik.values.customer_usa_state}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_city"
                label="Customer city"
                onChange={formik.handleChange}
                value={formik.values.customer_city}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_address"
                label="Customer address"
                onChange={formik.handleChange}
                value={formik.values.customer_address}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_zip_code"
                label="Customer zip code"
                onChange={formik.handleChange}
                value={formik.values.customer_zip_code}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
              <TextField
                id="customer_phone"
                label="Customer phone"
                onChange={formik.handleChange}
                value={formik.values.customer_phone}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>
            <LoadingButton
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              Submit
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
