import React, { memo, useEffect, useState, useCallback } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useUpdateEffect } from 'react-use';
import { Link, useLocation } from 'wouter';
import { Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { getWithdrawals, IWithdrawal } from '../../api/finance.api';
import { IColumn, InfiniteTable } from '../InfiniteTable';

const columns: Array<IColumn<IWithdrawal>> = [
  {
    label: 'Created At',
    field: 'created_at',
    format: (row) => (
      <>
        {new Date(row.created_at).toLocaleString()}
        <br />
        {formatDistanceToNow(new Date(row.created_at), { addSuffix: true })}
      </>
    ),
  },
  {
    label: 'Completed At',
    field: 'completed_at',
    format: (row) =>
      row.completed_at ? (
        <>
          {new Date(row.completed_at).toLocaleString()}
          <br />
          {formatDistanceToNow(new Date(row.completed_at), { addSuffix: true })}
        </>
      ) : (
        '—'
      ),
  },
  {
    label: 'Amount',
    field: 'amount',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    field: 'withdrawal_id',
    label: 'Withdrawal Id',
  },

  {
    label: 'Updated At',
    field: 'updated_at',
    format: (row) =>
      row.updated_at ? new Date(row.updated_at).toLocaleString() : '—',
  },
];

const LIMIT = 20;

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

export const Withdrawals = memo(() => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [withdrawals, setWithdrawals] = useState<Array<IWithdrawal>>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [, setLocation] = useLocation();

  const onSkip = useCallback(() => {
    setSkip((s) => s + LIMIT);
  }, []);

  const fetchWithdrawals = useCallback(async (skipLedger: number) => {
    setLoading(true);
    const { data, error } = await getWithdrawals(LIMIT, skipLedger);

    if (data) {
      if (data.length > 0) {
        setWithdrawals((s) => [...s, ...data]);
      } else {
        setHasMore(false);
      }
    }

    if (error) {
      setErrorMessage(error.message);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    void fetchWithdrawals(0);
  }, [fetchWithdrawals]);

  useUpdateEffect(() => {
    void fetchWithdrawals(skip);
  }, [fetchWithdrawals, skip]);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Link href="/withdrawal/create">
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add withdrawal
          </Button>
        </Link>
      </div>
      <InfiniteTable
        data={withdrawals}
        loading={loading}
        columns={columns}
        rowKey="withdrawal_id"
        errorMessage={errorMessage}
        hasMore={hasMore}
        onSkip={onSkip}
        onTableRowClick={(_, row) => {
          setLocation(`/withdrawal/${row.withdrawal_id}`);
        }}
      />
    </div>
  );
});
