import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import {
  formatDistanceToNow,
  subMonths,
  endOfDay,
  startOfDay,
  isValid,
  parseISO,
} from 'date-fns';
import { useTheme, makeStyles, Button, TextField } from '@material-ui/core';
import { Link } from 'wouter';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
  getLedger,
  ILedger,
  downloadCSV as downloadCSVApi,
} from '../../api/finance.api';
import { IColumn, InfiniteTable } from '../InfiniteTable';
import { downloadFile } from '../../helpers';

const LIMIT = 20;

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dates: {
    marginLeft: theme.spacing(1),
  },
  date: {
    marginLeft: theme.spacing(1),
    width: 180,
  },
}));

const now = new Date();
const dateFromDefault = subMonths(now, 1).toISOString().split('T')[0];
const dateToDefault = now.toISOString().split('T')[0];

export const Ledger = memo(() => {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState<Array<ILedger>>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  const areDatesValid = useMemo(
    () => isValid(parseISO(dateFrom)) && isValid(parseISO(dateTo)),
    [dateFrom, dateTo],
  );

  const columns = useMemo<Array<IColumn<ILedger>>>(
    () => [
      {
        label: 'Created At',
        field: 'created_at',
        format: (row) => (
          <>
            {new Date(row.created_at).toLocaleString()}
            <br />
            {formatDistanceToNow(new Date(row.created_at), { addSuffix: true })}
          </>
        ),
        minWidth: 180,
      },
      {
        label: 'Amount',
        field: 'amount',
        format: (row) => (
          <div
            style={{
              color:
                row.record_type === 'debit' ? theme.palette.success.dark : '',
              fontWeight: 500,
            }}
          >
            {row.record_type === 'credit' && '-'}
            {row.amount} USD
          </div>
        ),
        align: 'right',
        minWidth: 120,
      },
      {
        label: 'Origin',
        format: (row) => (
          <>
            <strong>
              {row.order_id ? 'Order' : 'Withdrawal'}
              {': '}
            </strong>
            <Link
              className={classes.link}
              href={
                row.order_id
                  ? `/order/${row.order_id}`
                  : `/withdrawal/${row.withdrawal_id}`
              }
            >
              {row.order_id || row.withdrawal_id}
            </Link>
          </>
        ),
      },
      {
        label: 'Record Id',
        field: 'record_id',
      },
      {
        label: 'External partner order id',
        field: 'external_partner_order_id',
      },
      {
        label: 'Order amount',
        field: 'order_fiat_currency_amount',
        format: (row) => (
          <div
            style={{
              color:
                row.record_type === 'debit' ? theme.palette.success.dark : '',
              fontWeight: 500,
            }}
          >
            {row.record_type === 'credit' && '-'}
            {Number(row.order_fiat_currency_amount).toFixed(2)} USD
          </div>
        ),
        align: 'right',
        minWidth: 140,
      },
      {
        label: 'Customer Id',
        field: 'cs_kyc_customer_id',
        format: (row) => (
          <Link
            className={classes.link}
            href={`/customer/${row.cs_kyc_customer_id}`}
          >
            {row.cs_kyc_customer_id}
          </Link>
        ),
      },
    ],
    [classes.link, theme.palette.success.dark],
  );

  const onSkip = useCallback(() => {
    setSkip((s) => s + LIMIT);
  }, []);

  useEffect(() => {
    if (areDatesValid) {
      setSkip(0);
      setLedger([]);
      setHasMore(true);
    }
  }, [dateFrom, dateTo, areDatesValid]);

  const fetchLedger = useCallback(
    async (skipLedger: number) => {
      if (areDatesValid) {
        setErrorMessage(undefined);
        setLoading(true);
        const { data, error } = await getLedger({
          limit: LIMIT,
          skip: skipLedger,
          from_date: startOfDay(parseISO(dateFrom)).toISOString(),
          to_date: endOfDay(parseISO(dateTo)).toISOString(),
        });

        if (data) {
          if (data.length > 0) {
            setLedger((s) => [...s, ...data]);
          } else {
            setHasMore(false);
          }
        }

        if (error) {
          setErrorMessage(error.message);
        }

        setLoading(false);
      }
    },
    [dateFrom, dateTo, areDatesValid],
  );

  useEffect(() => {
    void fetchLedger(skip);
  }, [fetchLedger, skip]);

  const downloadCSV = useCallback(async () => {
    if (areDatesValid) {
      const { response } = await downloadCSVApi({
        from_date: dateFrom,
        to_date: dateTo,
      });
      if (response) {
        const [, fileName] = response.headers['content-disposition'].split(
          'filename=',
        );
        downloadFile(response.data, fileName);
      }
    }
  }, [dateFrom, dateTo, areDatesValid]);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<CloudDownloadIcon />}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
        <div className={classes.dates}>
          <TextField
            label="From"
            variant="outlined"
            size="small"
            type="date"
            value={dateFrom}
            onChange={(evt) => {
              setDateFrom(evt.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.date}
          />
          <TextField
            label="To"
            variant="outlined"
            size="small"
            type="date"
            value={dateTo}
            onChange={(evt) => {
              setDateTo(evt.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.date}
          />
        </div>
      </div>
      <InfiniteTable
        data={ledger}
        loading={loading}
        columns={columns}
        rowKey="record_id"
        errorMessage={errorMessage}
        hasMore={hasMore}
        onSkip={onSkip}
      />
    </div>
  );
});
