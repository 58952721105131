import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'wouter';
import { makeStyles, TextField } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import { useDebounce } from 'react-use';

import {
  getCustomerByCustomerId,
  getCustomerByEmail,
  getCustomerByExternalId,
  getCustomers,
  ICustomer,
} from '../../api/customers.api';
import { IColumn, InfiniteTable } from '../InfiniteTable';
import { isCustomerId, isEmail } from '../../helpers';
import { IResponse } from '../../api/request';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  container: {
    maxHeight: 'calc(100vh - 200px)',
  },
  search: {
    width: 400,
  },
}));

const LIMIT = 20;

export const Customers = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const [customers, setCustomers] = useState<Array<ICustomer>>([]);
  const [, setLocation] = useLocation();

  const fetchCustomers = useCallback(async () => {
    setErrorMessage(undefined);
    setLoading(true);
    const { data, error } = await getCustomers(LIMIT, skip);
    if (data) {
      if (data.length > 0) {
        setCustomers((s) => [...s, ...data]);
      } else {
        setHasMore(false);
      }
    }
    if (error) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  }, [skip]);

  useEffect(() => {
    if (!search) {
      setHasMore(true);
      setCustomers([]);
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      void fetchCustomers();
    }
  }, [fetchCustomers, search]);

  const fetchCustomer = useCallback(async (value: string) => {
    setErrorMessage(undefined);
    setLoading(true);
    let response: IResponse<ICustomer> | undefined;
    if (isEmail(value)) {
      response = await getCustomerByEmail(value);
    } else if (isCustomerId(value)) {
      response = await getCustomerByCustomerId(value);
    } else {
      response = await getCustomerByExternalId(value);
    }

    const { data, error } = response;

    if (data) {
      setCustomers([data]);
    } else {
      setCustomers([]);
    }
    if (error) {
      setErrorMessage(error.message);
    }
    setHasMore(false);
    setLoading(false);
  }, []);

  useDebounce(
    () => {
      if (search) {
        setSkip(0);
        void fetchCustomer(search);
      }
    },
    300,
    [search, fetchCustomer],
  );

  const columns = useMemo<Array<IColumn<ICustomer>>>(() => {
    return [
      {
        field: 'customer_email',
        label: 'Customer Email',
      },
      {
        field: 'missing_kyc',
        label: 'Missing kyc',
        format: (row) => row.missing_kyc.join(', '),
      },
      {
        field: 'customer_id',
        label: 'Customer Id',
      },
      {
        field: 'customer_external_id',
        label: 'Customer External Id',
      },
      {
        field: 'created_at',
        label: 'Created At',
        format: (row) => {
          const createdAt = new Date(row.created_at);
          return (
            <>
              {createdAt.toLocaleString()}
              <br />
              {formatDistanceToNow(createdAt, { addSuffix: true })}
            </>
          );
        },
      },
      {
        field: 'updated_at',
        label: 'Updated At',
        format: (row) => {
          return new Date(row.updated_at).toLocaleString();
        },
      },
    ];
  }, []);

  const onRowClick = useCallback(
    (_, rowData?: ICustomer) => {
      setLocation(`/customer/${rowData?.customer_id}`);
    },
    [setLocation],
  );

  const onSkip = useCallback(() => {
    setSkip((s) => s + LIMIT);
  }, []);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <TextField
          className={classes.search}
          label="Search"
          placeholder="Customer Email or Customer Id"
          variant="outlined"
          size="small"
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
        />
      </div>
      <InfiniteTable
        columns={columns}
        data={customers}
        rowKey="customer_id"
        onSkip={onSkip}
        hasMore={hasMore}
        loading={loading}
        onTableRowClick={onRowClick}
        errorMessage={errorMessage}
      />
    </div>
  );
};
