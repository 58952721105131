import React, { memo } from 'react';
import {
  Typography,
  Input,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps, useLocation } from 'wouter';

import {
  createCustomerDocumentByCustomerId,
  ICreateCustomerDocument,
} from '../../api/customers.api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const CreateDocument = memo(({ params }: RouteComponentProps) => {
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const formik = useFormik<ICreateCustomerDocument>({
    initialValues: {
      kyc_request_type: 'identity',
      document_type: 'passport',
      total_files: 2,
    },
    onSubmit: async (values) => {
      const { ok } = await createCustomerDocumentByCustomerId(
        params.customerId,
        {
          ...values,
          total_files: Number(values.total_files),
        },
      );
      if (ok) {
        setLocation(`/customer/${params.customerId}`);
      }
    },
  });
  return (
    <div>
      <Typography gutterBottom variant="h4">
        Create Document
      </Typography>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.form}>
              <Input
                id="kyc_request_type"
                placeholder="Kyc request type"
                onChange={formik.handleChange}
                value={formik.values.kyc_request_type}
              />
              <Input
                id="document_type"
                placeholder="Document type"
                onChange={formik.handleChange}
                value={formik.values.document_type}
              />
              <Input
                id="total_files"
                placeholder="Total files"
                onChange={formik.handleChange}
                value={formik.values.total_files}
              />
            </div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
});
